import { AppConfig } from 'src/configuration';

import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

Sentry.init({
  dsn: AppConfig.env.SENTRY_DSN,

  release: AppConfig.env.SENTRY_RELEASE,
  environment: AppConfig.env.RAILS_ENV,

  // debug: true, // turn this on for debugging...

  ignoreErrors: [/ResizeObserver loop completed with undelivered notifications/],

  tracesSampleRate: 0.9,

  integrations: [
    Sentry.globalHandlersIntegration({
    // This integration attaches global handlers to capture uncaught exceptions and unhandled rejections.
      onunhandledrejection: false,
    }),

    // This is needed to send console.error to Sentry
    new CaptureConsole({ levels: ['error'] }),
  ],
});

if (AppConfig.env.SENTRY_ON) {
  const scope = Sentry.getCurrentScope();

  scope.setUser({
    id: AppConfig.user.id,
  });

  if (AppConfig.runtime.mobile_app_version) {
    scope.setTags({ appVersion: AppConfig.runtime.mobile_app_version });
  }
}
